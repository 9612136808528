<template>
  <div class="mailPopup" :class="{active}">

    <h3 style="margin-left: 5%;" class="pt20 mb20">Send Email</h3>
    <label for="">Addresses</label>
    <div class="emails">
      <div class="address px8 py4 inline-block" style="background: #eee;"
           v-for="(addr, ix) in addresses" :key="ix">{{addr.email}} 
           <i @click="removeEmails(ix)" class="myauto ml8 pointer close icon"></i>
      </div>
    </div>
    <input v-model="subject" class="popupEmailContent" placeholder="Subject" type="text">
    <div>
      <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="emailMenuBar">
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <i class="bold icon" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <i class="italic icon" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <i class="underline icon" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <i class="paragraph icon" />
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 1 }) }"
          @click="commands.heading({ level: 1 })"
        >
          H1
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>

        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>
        <button
          class="menubar__button"
          :class="{ 'is-active': isActive.link() }"
          @click="commands.link();"
          >
          <i class="chain icon" />
        </button>

        <button
          class="menubar__button"
          @click="commands.undo"
        >
          <i class="undo icon" />
        </button>

        <button
          class="menubar__button"
          @click="commands.redo"
        >
          <i class="redo icon" />
        </button>

      </div>
    </editor-menu-bar>
      <editor-content class="editor popupEmailContent content" :editor="editor" />
    </div>
    <div style="margin-right: 5%;" class="mlauto flex">
      <button class="black" @click="active = false">Cancel</button>
      <button class="brown500 sendEmailButton" @click="sendEmail">
        <span v-if="loading" class="ui active loader"></span>
        <span v-else>Send Email</span>
      </button>
    </div>
  </div>
</template>

<style lang="css">
  .ProseMirror {
    outline: none;
  }
  .emailMenuBar {
    margin: 0px 5%; 
  }
  .editor {
    padding: 12px;
    min-height: 160px;
  }
  .emailMenuBar__button {
    background: transparent;
    padding: 8px;
  }
  .emailMenuBar__button:hover {
    background: rgba(0,0,0,0.05)
  }
  .mailPopup {
    padding: 12px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  }
  .mailPopup label {
    margin-left: 5%;
  }
  .emails {
    width: 90%;
    margin: 8px 5%;
    border-radius: 4px;
    border: 1px solid #ccc;
    padding: 8px 4px;
    height: 80px;
    flex-shrink: 0;
    overflow-y: scroll;
  }
  .emails .address {
    margin: 4px 4px;
    border-radius: 2px;
    height: 28px;
    display: inline-flex;
    font-size: 12px;
  }
  .emails .address i {
    margin: auto 4px !important;
  }
  .popupEmailContent {
    width: 90%;
    margin: 8px 5%;
    font-size: 12px;
  }
  .sendEmailButton {
    margin-left: 12px !important;
    position: relative;
  }
</style>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from 'tiptap-extensions'
export default {
  name: 'mail-popup',
  props: {
    emails: {default: []},
    additional: {default: () => { return {}}}
  },
  components: {
    EditorMenuBar,
    EditorContent
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  data () {
    return {
      newAddress: '',
      addresses: this.emails,
      subject: '',
      content: '',
      active: false,
      loading: false,
      editor: new Editor({
        content: '',
        onUpdate: ({getHTML}) => {
          this.content = getHTML()
        },
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
        ],
      })
    }
  },
  watch: {
    emails: function (v) {
      this.addresses = [...v]
    }
  },
  methods: {
    removeEmails: function (ix) {
      this.addresses.splice(ix, 1)
    },
    sendEmail: function () {
      if (this.subject == '') { this.$bus.notify('Subject blank', 'error'); return; }
      if (this.content == '') { this.$bus.notify('Content blank', 'error'); return; }
      this.loading = true
      this.$req.post('mail', {
        emails: this.addresses,
        subject: this.subject,
        content: this.content
      }, {
        headers: {
          Authorization: `Bearer ${this.$bus.token}`
        }
      }).then(() => {
        alert('Mail Sent')
        this.loading = false
      }).catch(e => {
        alert('An Error Occured:' + e)
        this.loading = false
      })
    }
  }
}
</script>

<style lang="css">
  .mailPopup {
    position: absolute;
    left: 10%;
    top: 10%;
    width: 80%;
    min-height: 80%;
    background: #fff;
    z-index: 1;
    display: none;
  }

  .mailPopup.active {
    display: flex;
    flex-direction: column;
  }
  .popupEmailContent.content {
    border: 1px solid #cacacf;
  }
  .popupEmailContent.content:focus {
    border: 1px solid #bababf;
  }
</style>